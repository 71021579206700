import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
function Disclaimer() {
    return (
        <Layout>
            <div class="row">
                <div class="col-md-12">
                    <h1 className='text-center mb-4'>Disclaimer for Tax Calculator Pakistan</h1>

                    <p>If you require any more information or have any questions about our site's disclaimer, please feel
                        free to contact us by email at contact@taxcalculatorpk.com</p>

                    <h3>Disclaimers for taxcalculatorpk.com</h3>

                    <p>All the information on this website - https://taxcalculatorpk.com - is published in good faith and for
                        general information purpose only. taxcalculatorpk.com does not make any warranties about the
                        completeness, reliability and accuracy of this information. Any action you take upon the information
                        you find on this website (taxcalculatorpk.com), is strictly at your own risk. taxcalculatorpk.com will not be
                        liable for any losses and/or damages in connection with the use of our website.
                    </p>

                    <p>From our website, you can visit other websites by following hyperlinks to such external sites. While
                        we strive to provide only quality links to useful and ethical websites, we have no control over the
                        content and nature of these sites. These links to other websites do not imply a recommendation for
                        all the content found on these sites. Site owners and content may change without notice and may
                        occur before we have the opportunity to remove a link which may have gone 'bad'.</p>

                    <p>Please be also aware that when you leave our website, other sites may have different privacy policies
                        and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites
                        as well as their "Terms of Service" before engaging in any business or uploading any
                        information.</p>

                    <h3>Consent</h3>
                    <p>The materials appearing on Tax Calculator Pakistan could include technical, typographical, or photographic errors. Tax Calculator Pakistan does not warrant that any of the materials on its website are accurate, complete or current. Tax Calculator Pakistan may make changes to the materials contained on its website at any time without notice. However Tax Calculator Pakistan does not make any commitment to update the materials.</p>

                    <h3>Consent</h3>

                    <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>

                    <h3>Update</h3>

                    <p>Should we update, amend or make any changes to this document, those changes will be prominently
                        posted here.</p>
                </div>
            </div>
        </Layout>
    );
}
export const Head = () => <Seo title="Disclaimer | Tax Calculator Pakistan" />

export default Disclaimer;